import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/layout";
import Head from "../components/head";

const IndexPage = () => {
  const { site } = useStaticQuery(graphql`
    query MetaQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `);

  return (
    <Layout>
      <Head
        title={site.siteMetadata.title}
        description={site.siteMetadata.description}
      />
      <h1 className="title">Hi,</h1>
      <h2 className="subtitle">We are {site.siteMetadata.title}</h2>
      <h3 className="subtitle">{site.siteMetadata.description}</h3>
    </Layout>
  );
};

export default IndexPage;
